<script setup lang="ts">

import { ref } from 'vue'
import TheIcon from '@/Components/TheIcon.vue'
import ToggleElement from '@/Components/the-accordion/Components/ToggleElement.vue'

defineProps<{
    name: string
    city: string
    items: {
        title: string
        items: {
            label: string
            value: string
            displayType: string
        }[]
    }[]
}>()

const open = ref(false)

</script>

<template>
    <section class="my-3">
        <div class="toggle-section" @click.prevent="open = !open">
            <div role="tab" class="card-header slp-table-content-accordion-title py-0 w-100 d-flex justify-content-between align-items-center">
                <span class="accordion-link">
                    <strong>See all Freebies and Hostel Events</strong> at {{ name }}
                    <TheIcon
                        icon-id="arrow-bottom-2"
                        width="12"
                        height="12"
                        class="transition d-inline-flex"
                        :class="{'rotate' : open}"
                        style="margin-left: 12px;"
                    />
                </span>
            </div>
        </div>

        <ToggleElement :show="open">
            <div class="card border-0 shadow">
                <div class="card-body py-5">
                    <div class="d-flex flex-column justify-content-center justify-content-sm-around flex-sm-row">
                        <div v-for="(item, key) in items" :key class="border-bottom mb-3 pb-3">
                            <h4 class="cl-text tx-body mb-3 bg-white flex-center font-weight-bold">
                                {{ item.title }}
                            </h4>
                            <div
                                v-for="(feature, index) in item.items"
                                :key="index"
                                class="d-flex align-items-center mb-2 tx-small"
                            >
                                <template v-if="feature.displayType === 'labelValuePair'">
                                    <TheIcon icon-id="info" width="24" height="24" />
                                    <p class="cl-subtext mb-0 ml-2">
                                        {{ feature.label }}: <span class="cl-text">{{ feature.value }}</span>
                                    </p>
                                </template>
                                <template v-else>
                                    <TheIcon
                                        v-if="['yes', 'free'].includes(feature.displayType)"
                                        icon-id="green-check"
                                        width="24"
                                        height="24"
                                    />
                                    <TheIcon
                                        v-else-if="feature.displayType === 'no'"
                                        icon-id="red-restriction"
                                        width="24"
                                        height="24"
                                    />
                                    <TheIcon
                                        v-else-if="feature.displayType === 'pay'"
                                        icon-id="green-label"
                                        width="24"
                                        height="24"
                                    />

                                    <p class="cl-subtext mb-0 ml-2">
                                        {{ feature.label }}
                                        <span v-if="feature.value" class="cl-text">
                                            {{ feature.value }}
                                        </span>
                                    </p>
                                </template>
                            </div>
                        </div>
                    </div>
                    <p class="mb-0 text-sm">
                        <strong>Coming soon:</strong> We all love social hostels! We are currently workin on showing you all the events and acitivites at {{ name }}.
                    </p>
                </div>
            </div>
        </ToggleElement>
    </section>
</template>

<style scoped lang="scss">
button {
    border: none;
    background-color: transparent;
    outline: none;
    padding: 0;
}

.rotate {
    transform: rotateX(180deg);
}
</style>